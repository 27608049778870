/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useEffect } from 'react'
import api from '../services/api'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const AuthContext = createContext({})

// eslint-disable-next-line react/prop-types
export const AuthProvider = ({ children }) => {
  const navigate = useNavigate()
  const [signed, setSigned] = useState(false)
  const [user, setUser] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    async function loadStore() {
      const storedToken = localStorage.getItem('token')
      api.defaults.headers.common = { Authorization: `Bearer ${storedToken}` }
      !!storedToken ? getUser() : signOut()
    }
    loadStore()
  }, [])

  async function signIn(credentials) {
    console.log(credentials)
    setLoading(true)
    await api
      .post('/login', {
        email: credentials.email,
        senha: credentials.senha,
      })
      .then((response) => {
        let token = response.headers['authorization'] // token
        let refreshToken = response.data['refreshToken']
        localStorage.setItem('token', token)
        localStorage.setItem('refreshToken', refreshToken)
        api.defaults.headers.common = { Authorization: `Bearer ${token}` }
        getUser()
      })
      .catch((err) => {
        toast.error('Usuario ou senha incorretos!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function getUser() {
    api
      .get('/usuario/me')
      .then((response) => {
        if (response.data !== undefined) {
          setUser(response.data)
          setSigned(true)
          navigate('/')
        }
      })
      .catch((err) => {
        console.log('get user error: ')
        console.log(err.response.data)
        setSigned(false)
        signOut()
      })
    setLoading(false)
  }

  function signOut() {
    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken')
    setSigned(false)
    setLoading(false)
  }

  return (
    <AuthContext.Provider value={{ signed: signed, loading, user, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext
